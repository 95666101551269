export let joblist = [{
	"id": 1001,
	"name": "IT服务",
	
	"icon": ""
}, {
	"id": 1002,
	"name": "制造/原材",
	
	"icon": ""
}, {
	"id": 1003,
	"name": "批发/零售",
	
	"icon": ""
}, {
	"id": 1004,
	"name": "生活服务",
	
	"icon": ""
}, {
	"id": 1005,
	"name": "文娱/体育",
	
	"icon": ""
}, {
	"id": 1006,
	"name": "建筑/地产",
	
	"icon": ""
}, {
	"id": 1007,
	"name": "教育/培训",
	
	"icon": ""
}, {
	"id": 1008,
	"name": "物流/仓储",
	
	"icon": ""
}, {
	"id": 1009,
	"name": "医疗/医美",
	
	"icon": ""
}, {
	"id": 1010,
	"name": "政府/组织",
	
	"icon": ""
}, {
	"id": 1011,
	"name": "金融/信贷",
	
	"icon": ""
}, {
	"id": 1012,
	"name": "能源/采矿",
	
	"icon": ""
}, {
	"id": 1013,
	"name": "农林/渔牧",
	
	"icon": ""
}, {
	"id": 1014,
	"name": "其他行业",
	
	"icon": ""
}]
