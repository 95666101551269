<template>
  <div id="createStore" v-loading="loading">
    <div class="widthbox">
      <div class="headbox">
        <div class="logo">
          <el-image style="border-radius: 50%;" :src="logoimg" fit="contain"></el-image>
        </div>
        <div class="title" style="font-weight: bold;">创建店铺</div>
        <div class="userinfo" style="font-weight: bold;">
          <div class="userphone">+86 - {{ admin }}</div>
          <div class="loginout" @click="loginout">退出</div>
        </div>
      </div>
    </div>
    <div class="content" v-if="type == 1">
      <div class="title" style="font-weight: bold;color:#000">请选择需要创建的店铺类型 </div>
		<div style="overflow: hidden;width: 100%;display: block;display: flex;
		flex-wrap: wrap;">
			<div v-if="list.length<=0" style="color: #999;text-align: center;padding: 30px;">暂无数据</div>
			<div v-for="(item,index) in list" :key="index" class="applist_item">
				<div class="applist_head">
					<img :src="item.image_url" alt="" />
					<span>{{item.app_name}}</span>
				</div>
				<div class="applist_status">{{item.description}}</div>
				<!-- <div class="applist_introduct">{{item.description}}{{item.description}}</div> -->
				<div class="applist_but" @click="createStore(item)">
					立即开店
				</div>
			</div>
		</div>
     <!-- <el-row :gutter="20" class="cardbox">
        <el-col :span="6" v-for="(item,index) in list" :key="index">
          <div class="card">
            <div class="top">
              <el-image class="icon" :src="item.img" fit="fill"></el-image>
              <div class="name">{{ item.app_name }}</div>
            </div>
            <div class="text" :title="item.desc">{{ item.desc }}</div>
            <div class="btns">
              <el-button type="primary" plain size="small" @click="createStore(item.id)">立即开店</el-button>
            </div>
          </div>
        </el-col>
      </el-row> -->
      <div class="typetext" style="font-size:14px">以上店铺类型都可开通微信小程序</div>
      <div class="tips"  style="font-size:14px">不知道选哪个？欢迎拨打开店咨询热线：17380566578</div>
    </div>

    <div class="frombox" v-if="type == 2">
      <div class="inputbox">
        <div class="label">店铺名称</div>
        <el-input class="input" v-model="userinfo.store_name" placeholder="请输入店铺名称" />
      </div>
      <div class="inputbox">
        <div class="label">省市区</div>
        <el-cascader class="input" size="large" :options="options" @change="pickadd"></el-cascader>
      </div>
      <div class="inputbox">
        <div class="label">选择行业</div>
        <el-select class="input" v-model="userinfo.industry" placeholder="请选择您的行业">
          <el-option v-for="item in joblist" :key="item.index" :label="item.name" :value="item.name"></el-option>
        </el-select>
      </div>
      <div class="inputbox">
        <div class="label">联系人</div>
        <el-input class="input" :maxlength="4" v-model="userinfo.user_name" placeholder="请输入联系人" />
      </div>
      <div class="inputbox">
        <div class="label">联系电话</div>
        <el-input class="input" v-model="userinfo.phone" placeholder="请输入联系电话" />
        <div class="codesty" v-if="!codeshow" @click="getcodes">获取验证码</div>
        <div class="codesty" v-else>{{ codetime }}后再获取</div>
      </div>
      <div class="inputbox">
        <div class="label">验证码</div>
        <el-input class="input" v-model="userinfo.code" placeholder="请输入验证码" />
      </div>

      <div class="sendbtn" @click="establish">立即创建</div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data';
import { joblist } from '@/util/job.js';
import { httpurl, eltips } from '@/util/util.js';
import { resetRouter } from '@/router/index.js';
export default {
  name: '',
  data() {
    return {
      admin: JSON.parse(sessionStorage.getItem('userInfo'))?.mobile,
      list: [],
      type: 1,
      options: regionData,
      userinfo: {
        store_name: '',
        province: '',
        city: '',
        area: '',
        industry: '',
        user_name: '',
        phone: '',
        code: ''
      },
      logoimg: '',
      id: '',
      ids: '',
      codeshow: false,
      codetime: 59,
      loading: true,
      joblist: joblist,
    };
  },
  created() {
    this.httpurl = httpurl;
    this.getapplist();
    if (this.$route.query.unitid) {
      this.createStore(this.$route.query.unitid);
    }
	let config = sessionStorage.getItem('configIndex')
	if(config){
		config = JSON.parse(config)
		this.logoimg = config.site_logo_url
	}
  },
  methods: {
    //获取应用列表
    getapplist() {
      this.axios.get('/api/shop/basic/application-index').then(res => {
        if (res.code == 200) {
          this.list = res.data.data
          this.loading = false
        } else {
          this.loading = false
          eltips(res.msg, 'error');
        }
      }).catch(error => { });
    },
    //退出登录
    loginout() {
      this.axios.get('/api/shop/basic/logout').then(res => {
          if (res.code == 200) {
            sessionStorage.removeItem('userInfo');
            this.$router.push({
              path: '/login'
            });
          } else {
            eltips(res.msg, 'error');
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //立即开店
    createStore(e) {
		// this.$router.replace({
		//   path: '/index/createStore_user'
		// });
		this.$router.push({              // 推送方法
			path: '/index/createStore_user',   //导航的路径
			query: {
				id:e.id,
				title:e.app_name
			} 
		})
      // this.ids = e;
      // this.type = 2;
    },
    //选择区域
    pickadd(e) {
      this.userinfo.province = CodeToText[e[0]];
      this.userinfo.city = CodeToText[e[1]];
      this.userinfo.area = CodeToText[e[2]];
    },
    //获取验证码
    getcodes() {
      this.axios
        .post('/api/send_code', { phone: this.userinfo.phone })
        .then(res => {
          if (res.code == 200) {
            eltips(res.msg, 'success');
            this.codeshow = true;
            let autocode = setInterval(() => {
              if (this.codetime > 1) {
                this.codetime--;
              } else {
                this.codeshow = false;
                this.codetime = 59;
                clearInterval(autocode);
              }
            }, 1000);
          } else {
            eltips(res.msg, 'error');
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //	创建
    establish() {
      if (!this.userinfo.store_name) {
        eltips('请输入店铺名称', 'warning');
        return;
      }

      if (!this.userinfo.province || !this.userinfo.city || !this.userinfo.area) {
        eltips('请选择区域', 'warning');
        return;
      }

      if (!this.userinfo.industry) {
        eltips('请输入行业', 'warning');
        return;
      }

      if (!this.userinfo.phone) {
        eltips('请输入联系电话', 'warning');
        return;
      }

      if (!this.userinfo.code) {
        eltips('请输入验证码', 'warning');
        return;
      }

      this.axios.post('/api/user/create_shop', {
          unitid: this.ids,
          shop_name: this.userinfo.store_name,
          province: this.userinfo.province,
          city: this.userinfo.city,
          area: this.userinfo.area,
          industry: this.userinfo.industry,
          name: this.userinfo.user_name,
          phone: this.userinfo.phone,
          sms_code: this.userinfo.code
        })
        .then(res => {
          console.log(res);
          if (res.code == 200) {
            eltips(res.msg, 'success');
            this.getInfo();
          } else {
            eltips(res.msg, 'error');
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取菜单
    getmeuns() {
      this.axios.get('/api/get_menu').then(res => {
        resetRouter();
        this.$store.commit('routestatus', 0);
        sessionStorage.removeItem('routestext');
        sessionStorage.removeItem('routes');
        sessionStorage.setItem('routes', JSON.stringify(res.data));
        this.$router.replace({ path: '/index' });
      });
    },
    getInfo() {
      this.axios
        .get('/api/me', { params: { guard_name: 'api' } })
        .then(res => {
          if (res.code == 200) {
            sessionStorage.setItem('userinfo', JSON.stringify(res.data));
            if (res.data.unitid == 2) {
              sessionStorage.setItem('role_id', res.data.roles[0].id);
            }
            this.getmeuns();
          } else {
            eltips(res.msg, 'error');
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
	.applist_item{
		// width:calc(16.6% - 8px);
		flex: 0 0 calc((100% - 50px)/6);
		margin-right:10px;
		box-shadow: 0 5px 10px 0 rgba(2,31,65,.05);
		background: #fff;
		float: left;
		margin-bottom: 10px;
		border-radius: 4px;
		padding: 30px 36px 36px 36px;
		height: 250px;
		cursor: pointer;
		&:nth-child(6n){
			margin-right: 0;
		}
	}
	.applist{
		&_head{
			display: flex;
			align-items: center;
			border-bottom: 1px solid rgba(0,11,23,.06);
			padding-bottom: 15px;
			
			img{
				width: 36px;
				height: 36px;
				margin-right: 8px;
			}
			span{
				font-size: 18px;
				font-weight: 500;
			}
		}
		&_status{
			padding-top: 20px;
			color: #282d37;
			margin-bottom: 20px;
			height: 78px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;//控制行数   当前为3行
			overflow: hidden;
		}
		&_introduct{
			color: #808082;
			line-height: 24px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;//控制行数   当前为3行
			overflow: hidden;
			opacity: 1;
			height: 72px;
			-webkit-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
		}
		&_but{
			display: block;
			height: 38px;
			line-height: 37px;
			width: 102px;
			cursor: pointer;
			border-radius: 2px;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			border: 1px solid #0071ef;
			color: #0071ef;;
			background-color: #fff;
			-webkit-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
			&:hover{
				
				color: #fff;
				background-color: #0071ef;
				-webkit-transition: all .3s ease-in-out;
				transition: all .3s ease-in-out;
			}
		}
	}
</style>

<style lang="less" scoped>
.widthbox {
  width: 100%;
  background: #ffffff;
}
.headbox {
  width: 1080px;
  height: 66px;
  background: #ffffff;
  margin: 0 auto;
  padding: 0 30px;

  div {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
  }

  .logo {
    width: 50px;
    height: 66px;
    // background: #0C77DC;
    color: #ffffff;
    text-align: center;
    position: relative;
	display: flex;
	align-items: center;
	float: left;
    // top: 15px;
    img {
      width: 50px;
      height: 50px;
    }
  }

  .title {
    height: 66px;
    line-height: 66px;
    margin-left: 25px;
    padding-left: 25px;
    position: relative;
    font-weight: bold;
    font-size: 16px !important;
    &::after {
      content: "";
      width: 1px;
      height: 24px;
      background: #000000;
      position: absolute;
      left: 0;
      top: 21px;
    }
  }

  .userinfo {
    width: auto;
    float: right;
    line-height: 66px;
    font-size: 14px;
    .userphone {
      color: #333;
    }
    .loginout {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}

.content {
  width: calc(100% - 40px);
  // width: 1260px;
  margin: 0 auto 40px;
  font-size: 18px;
  text-align: center;

  .title {
    font-weight: bold;
    height: 90px;
    line-height: 90px;
  }

  .listbox {
    margin-top: 60px;
    text-align: left;
    .list {
      text-align: center;
      display: inline-block;
      vertical-align: top;
      width: 200px;
      height: 270px;
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;
      padding: 30px 20px 0;
      border-radius: 6px;
      color: #ffffff;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.5);
      &:nth-child(5n) {
        margin-right: 0;
      }

      .title {
        font-size: 20px;
        position: relative;
        z-index: 9;
      }

      .text {
        font-size: 14px;
        margin-top: 10px;
        position: relative;
        z-index: 9;
        font-weight: bold;
      }

      .btn {
        width: 110px;
        line-height: 42px;
        font-size: 14px;
        color: #0087ff;
        background: #ffffff;
        border-radius: 2px;
        position: absolute;
        left: 45px;
        bottom: 30px;
        z-index: 9;
        cursor: pointer;
        font-weight: bold;
        // box-shadow: 0 4px 4px 0 rgba(0,0,0,.5);
      }

      .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .c1 {
      background: #fd5e53;
    }

    .c2 {
      background: #42c2ff;
    }

    .c3 {
      background: #ff5e99;
    }

    .c4 {
      background: #4ddb7d;
    }

    .c5 {
      background: #ffbd00;
    }
  }

  .typetext {
    margin-top: 20px;
  }

  .tips {
    margin-top: 20px;
    font-size: 16px;
    color: #666666;
  }
}

.frombox {
  margin: 50px auto 0;
  width: 500px !important;
  background: #ffffff;
  border-radius: 15px;
  padding: 40px 50px;
  font-size: 15px;
  .inputbox {
    position: relative;
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 20px;
    padding-bottom: 10px;
    .label {
      display: inline-block;
      vertical-align: top;
      width: 110px;
      line-height: 38px;
      font-weight: bold;
    }

    .input {
      display: inline-block;
      vertical-align: top;
      width: 290px;
    }

    .codesty {
      line-height: 38px;
      // color: #1E92FF;
      position: absolute;
      right: 10px;
      top: 0;
      cursor: pointer;
    }
  }

  .sendbtn {
    margin-top: 60px;
    line-height: 66px;
    text-align: center;
    background: #0c77dc;
    border-radius: 4px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      background: #1e92ff;
    }
  }
}

.cardbox {
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: space-between;
  .card {
    // flex: 0 0 280px;
    padding: 15px;
    margin-bottom: 25px;
    background: #ffffff;
    box-shadow: 0 5px 10px 0 rgba(2, 31, 65, 0.05);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:nth-child(5n) {
      margin-right: 0;
    }

    .top {
      display: flex;
      align-items: center;
      .icon {
        flex: 0 24px;
        height: 24px;
      }
      .name {
        flex: auto;
        text-align: left;
        padding-left: 10px;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        z-index: 1;
      }
    }

    .text {
      margin: 10px 0px;
      font-size: 16px;
      overflow: hidden;
      color: #b2b6bf;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }

    .btns {
      height: 48px;
      display: flex;
      align-items: center;
      .el-button {
        font-size: 16px;
      }
    }
  }
}
</style>
